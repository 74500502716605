import type { Location } from '~/composables/useCmsMedia';
import sessionHelper from '~/utils/sessionHelper';

export interface User {
  id: string;
  name: string;
  firstName: string | null;
  lastName: string | null;
  country?: string;
  profession?: string;
  specialty?: string;
  specialtyOther?: string;
  professionalTrack?: string;
  yearsOfExperience?: string;
  profileImage: string;
  email: string;
  roles: UserRole[];
  group?: undefined | {
    groupUuid: string;
    groupName: string;
    memberRole: string;
    memberStatus: string;
  };
  subscription: {
    type: SubscriptionType;
  };
  isVerified: boolean;
  blockedFromMailing: boolean;
  registrationCompleted: boolean;
  social?: {
    provider?: string;
    providerId?: string;
  };
  planType: string;
  traceId?: string;
  profilePicture?: Location | null;
  numberOfUnreadNotifications: number;
}

// fixme: move to enums, use
export enum SubscriptionType {
  trial = 'trial',
  basic = 'basic',
  pro = 'pro',
}

type UserRole = 'member' | 'professional' | 'Group manager' | 'Group owner' | 'Group member';

export default function useAuthentication () {

  const checkAuthenticationStatus = async () => {
    const { user } = useUserStore();
    return !!user.value;
  };

  const getUser = async (updateStore: boolean = true) => {
    try {
      const response = await $fetch('/api/auth/user', { credentials: 'include' });

      if (updateStore) {
        useUserStore().setUser(response as User);
      }

      return response as User;
    } catch (error) {
      console.error('[authentication failed]', error);
      await _checkGetUserError(error);
      return null;
    }
  };

  const _checkGetUserError = async (error: unknown) => {
    if (!error || typeof error !== 'object' || !('status' in error)) {
      return;
    }

    if ([403, 500].includes(Number(error.status))) {
      await logout();
      return;
    }

    return;
  };

  const login = async (email: string, password: string) => {
    await useAuthenticatedFetch('/api/auth/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      },
      body: {
        email,
        password,
      },
    });
    await getUser();
  };

  const logout = async (location: string = '/auth/login') => {
    try {
      useNuxtApp().$dataLayerReset();

      await $fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
        onRequest: sessionHelper.fetchOnRequestInterceptor,
      });
    } catch (error) {
      console.error(error);
    }

    useUserStore().setUser(null);
    navigateTo(location);
  };

  return {
    checkAuthenticationStatus,
    getUser,
    login,
    logout,
  };
}
